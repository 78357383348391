import { lazy } from "react";
import { Navigate } from "react-router-dom"; // Import Navigate and useRoutes

// project import
import MainLayout from "layout/MainLayout";
import CommonLayout from "layout/CommonLayout";
import Loadable from "components/Loadable";

// pages routing
const MaintenanceError = Loadable(lazy(() => import("pages/maintenance/404")));
const MaintenanceError500 = Loadable(
  lazy(() => import("pages/maintenance/500"))
);
const MaintenanceUnderConstruction = Loadable(
  lazy(() => import("pages/maintenance/under-construction"))
);
const MaintenanceComingSoon = Loadable(
  lazy(() => import("pages/maintenance/coming-soon"))
);

// render - dashboard
const Dashboard = Loadable(lazy(() => import("pages/apps/invoice/dashboard")));
const OpenDebitors = Loadable(
  lazy(() => import("pages/apps/invoice/opendebitors"))
);
const ReminderList = Loadable(
  lazy(() => import("pages/apps/invoice/reminderList"))
);
const Queue = Loadable(lazy(() => import("pages/apps/invoice/queue")));
const History = Loadable(lazy(() => import("pages/apps/invoice/history")));
const OpenDebitorlistDetails = Loadable(
  lazy(() => import("pages/apps/invoice/list"))
);
const CreateInvoice = Loadable(
  lazy(() => import("pages/apps/invoice/createInvoice"))
);

// user profile
const UserProfile = Loadable(lazy(() => import("pages/apps/profiles/user")));
const UserTabPersonal = Loadable(
  lazy(() => import("sections/apps/profiles/user/TabPersonal"))
);
const UserTabPayment = Loadable(
  lazy(() => import("sections/apps/profiles/user/TabPayment"))
);
const UserTabPassword = Loadable(
  lazy(() => import("sections/apps/profiles/user/TabPassword"))
);
const AccountTabRole = Loadable(
  lazy(() => import("sections/apps/profiles/account/TabRole"))
);
const UserTabSettings = Loadable(
  lazy(() => import("sections/apps/profiles/user/TabSettings"))
);

//user account
const AccountProfile = Loadable(
  lazy(() => import("pages/apps/profiles/account"))
);
const AccountTabProfile = Loadable(
  lazy(() => import("sections/apps/profiles/account/TabProfile"))
);
const AccountTabPersonal = Loadable(
  lazy(() => import("sections/apps/profiles/account/TabPersonal"))
);
const AccountTabAccount = Loadable(
  lazy(() => import("sections/apps/profiles/account/TabAccount"))
);
const AccountTabPassword = Loadable(
  lazy(() => import("sections/apps/profiles/account/TabPassword"))
);
const AccountTabSettings = Loadable(
  lazy(() => import("sections/apps/profiles/account/TabSettings"))
);

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: "/",
  children: [
    {
      path: "/",
      element: <Navigate to="/dashboard" />, // Redirect root to dashboard
    },
    {
      path: "/",
      element: <MainLayout />,
      children: [
        {
          path: "dashboard",
          element: <Dashboard />,
        },
        {
          path: "opendebitors",
          element: <OpenDebitors />,
        },
        {
          path: "reminderlist",
          element: <ReminderList />,
        },
        {
          path: "queue",
          element: <Queue />,
        },
        {
          path: "createInvoice",
          element: <CreateInvoice />,
        },
        {
          path: "log",
          element: <History />,
        },
        {
          path: "openDebitorlistDetails",
          element: <OpenDebitorlistDetails />,
        },

        {
          path: "account",
          element: <AccountProfile />,
          children: [
            {
              path: "basic",
              element: <AccountTabProfile />,
            },
            {
              path: "personal",
              element: <AccountTabPersonal />,
            },
            {
              path: "my-account",
              element: <AccountTabAccount />,
            },
            {
              path: "password",
              element: <AccountTabPassword />,
            },
            {
              path: "role",
              element: <AccountTabRole />,
            },
            {
              path: "settings",
              element: <AccountTabSettings />,
            },
          ],
        },
        {
          path: "user",
          element: <UserProfile />,
          children: [
            {
              path: "personal",
              element: <UserTabPersonal />,
            },
            {
              path: "payment",
              element: <UserTabPayment />,
            },
            {
              path: "password",
              element: <UserTabPassword />,
            },
            {
              path: "settings",
              element: <UserTabSettings />,
            },
          ],
        },
      ],
    },
    {
      path: "/maintenance",
      element: <CommonLayout />,
      children: [
        {
          path: "404",
          element: <MaintenanceError />,
        },
        {
          path: "500",
          element: <MaintenanceError500 />,
        },
        {
          path: "under-construction",
          element: <MaintenanceUnderConstruction />,
        },
        {
          path: "coming-soon",
          element: <MaintenanceComingSoon />,
        },
      ],
    },
    {
      path: "*", // Catch-all route for undefined paths
      element: <Navigate to="/maintenance/404" />,
    },
  ],
};

export default MainRoutes;
