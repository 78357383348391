import { useState } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Box,
} from "@mui/material";

// project import
// import DefaultThemeMode from "./ThemeMode";
import IconButton from "components/@extended/IconButton";

import { ThemeMode } from "types/config";
import useConfig from "hooks/useConfig";


// ==============================|| HEADER CONTENT - CUSTOMIZATION ||============================== //

const Customization = () => {
  const theme = useTheme();
  const { mode, onChangeMode } = useConfig();  // Assuming onChangeMode is a function from useConfig to toggle the mode

  // State to track whether the drawer is open
  const [open, setOpen] = useState(false);

  // Handle the toggle of the drawer
  const handleToggle = () => {
    setOpen(!open);
  };

  // Handle the theme mode change and icon swap
  const handleModeChange = () => {
    onChangeMode(mode === ThemeMode.LIGHT ? ThemeMode.DARK : ThemeMode.LIGHT);
  };

  // Conditionally set the icon based on the current theme mode
  const icon = mode === ThemeMode.LIGHT ? "nightlight" : "wb_sunny";

  const iconBackColorOpen =
    theme.palette.mode === ThemeMode.DARK ? "grey.200" : "grey.300";
  const iconBackColor =
    theme.palette.mode === ThemeMode.DARK ? "background.default" : "grey.100";

  return (
    <>
      <Box sx={{ flexShrink: 0, ml: 0.75 }}>
        <IconButton
          color="secondary"
          variant="light"
          sx={{
            color: "text.primary",
            bgcolor: open ? iconBackColorOpen : iconBackColor,
          }}
          onClick={() => {
            handleToggle();
            handleModeChange(); // Change the theme mode when clicked
          }}
          aria-label="settings toggler"
        >
          <span
            className="material-icons"
            style={{ transform: "rotate(-30deg)" }}
          >
            {icon} {/* Dynamically update the icon */}
          </span>
        </IconButton>
      </Box>
    </>
  );
};

export default Customization;
