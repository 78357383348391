// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { Dashboard, DescriptionOutlined, AddAlert, AddToQueue, WatchLaterOutlined, Add } from '@material-ui/icons';

// type
import { NavItemType } from 'types/menu';

// icons
const icons = {
  Dashboard,
  DescriptionOutlined,
  AddAlert,
  WatchLaterOutlined,
  AddToQueue,
  Add
};

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const other: NavItemType = {
  id: 'other',
  title: <FormattedMessage id="others" />,
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: <FormattedMessage id="dashboard" />,
      type: 'item',
      url: '/dashboard',
      icon: icons.Dashboard
    },
    {
      id: 'queue',
      title: <FormattedMessage id="queue" />,
      type: 'item',
      url: '/queue',
      icon: icons.AddToQueue
    },
    {
      id: 'opendebitors',
      title: <FormattedMessage id="opendebitors" />,
      type: 'item',
      url: '/opendebitors',
      icon: icons.DescriptionOutlined
    },
    {
      id: 'reminderlist',
      title: <FormattedMessage id="reminderlist" />,
      type: 'item',
      url: '/reminderlist',
      icon: icons.AddAlert
    },
    {
      id: 'createInvoice',
      title: <FormattedMessage id="createInvoice" />,
      type: 'item',
      url: '/createInvoice',
      icon: icons.Add
    },
    {
      id: 'log',
      title: <FormattedMessage id="log" />,
      type: 'item',
      url: '/log',
      icon: icons.WatchLaterOutlined
    }
  ]
};

export default other;
